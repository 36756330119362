import React, { Component, PropsWithChildren } from "react";
import logo from "./logo.svg";
import {
  OpenSheetMusicDisplay as OSMD,
  IOSMDOptions,
} from "opensheetmusicdisplay";
import config from "./config"
import "./App.css";

interface Props {
  autoResize: boolean;
  drawTitle: boolean;
  file: string;
  refreshTimestamp: number;
}

const server = config.serverOrigin;
const endpoint = `${server}/sightreading-snippet`;

const OpenSheetMusicDisplay = function(props: Props) {
  let [dataReady, setDataReady] = React.useState(false);
  let ref = React.useRef<HTMLDivElement>(null);
  let [osmd, setOsmd] = React.useState<OSMD | null>(null);
  let [resizeState, setResizeState] = React.useState(0);

  React.useEffect(() => {
    function resizeListener(evt: UIEvent) {
      setResizeState((resizeState) => resizeState + 1);
    }
    const options: IOSMDOptions = {
      autoResize: props.autoResize !== undefined ? props.autoResize : true,

      drawTitle: props.drawTitle !== undefined ? props.drawTitle : true,
    };

    setOsmd(new OSMD(ref.current as HTMLElement, options));
    if (options.autoResize) {
      window.addEventListener("resize", resizeListener);
      return function() {
        window.removeEventListener("resize", resizeListener);
      };
    }
  }, [props.drawTitle, props.autoResize]);

  // this runs whenever osmd is ready or the file is changed
  React.useEffect(() => {
    console.log(osmd);
    setDataReady(false);
    if (osmd) {
      osmd
        .load(props.file)
        .then(() => osmd && osmd.render())
        .then(() => {
          setDataReady(true);
        });
    }
  }, [osmd, props.file, props.refreshTimestamp]);

  if (!ref.current === null) {
    return <div>loading...</div>;
  }
  let actualContent = <div ref={ref} />;
  return (
    <>
      {!dataReady && <p>loading...</p>}
      {actualContent}
    </>
  );
};

function App() {
  const [numberBars, setNumberBars] = React.useState(8);
  const [refreshTimestamp, setRefreshTimestamp] = React.useState(0);
  return (
    <div className="App">
      <div id="layout" style={{ display: "flex", flexDirection: "column" }}>
        <div
          id="header"
          style={{
            display: "flex",
            flexDirection: "column",
            flexShrink: 1,
          }}
        >
          <div id="title-bar" style={{ height: "120px" }}></div>
          <h1 style={{ alignSelf: "center" }}>Sheet Music</h1>
        </div>
        <div id="sheet-music-viewer">
          <OpenSheetMusicDisplay
            autoResize={true}
            file={`${endpoint}?bars=${numberBars}`}
            drawTitle={true}
            refreshTimestamp={refreshTimestamp}
          />
          {/* <OpenSheetMusicDisplay
            autoResize={true}
            file={"Tin2002-Fecit_potentiam.musicxml"}
            drawTitle={true}
          /> */}
        </div>
      </div>
      <div id="refresh-button">
        <button onClick={() => setRefreshTimestamp((r) => r + 1)}>
          Refresh
        </button>
      </div>
      <p>current bars: {numberBars}</p>
      <div id="bars-selector">
        <button
          style={{ marginRight: "10px" }}
          disabled={numberBars === 1}
          onClick={() => setNumberBars(Math.max(numberBars - 1, 1))}
        >
          &minus;
        </button>

        <button disabled={numberBars >= 24} onClick={() => setNumberBars(Math.min(numberBars + 1, 24))}>&#43;</button>
      </div>
    </div>
  );
}

export default App;
