let config: {
  serverOrigin: string;
};

if (process.env.NODE_ENV === "production") {
  config = {
    serverOrigin:
      process.env.REACT_APP_SERVER_ORIGIN || "https://sightreading.app",
  };
} else if (process.env.NODE_ENV === "development") {
  config = {
    serverOrigin:
      process.env.REACT_APP_SERVER_ORIGIN ||
      "https://dev.sightreading.app",
  };
} else {
  // unknown?
  config = {
    serverOrigin: process.env.REACT_APP_SERVER_ORIGIN || "",
  };
}

export default config;
